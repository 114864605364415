.field:not(:last-child) {

    margin-bottom: 1rem !important;
}

.field ion-label{

     

        padding-left: 0.2rem;
        padding-right: 0.5rem;
        color: #00BCD4;
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;

    
}
.customInput {

    --background: #096773;
    --padding-bottom: 1rem;
    --padding-top: 1rem;
    --padding-start: 1rem;
    --padding-end: 1rem;
    border-radius: 10px;
    margin-top: 0.25rem;
    transition: all 0.2s linear;
}