.spin{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
 }
 ion-spinner {
    width: 50px;
    height: 50px;
    stroke: #444;
    fill: #222;
 }

 .component-icon {
   border-radius: 50%;
 
   padding: 7px;
   height: 18px;
   width: 18px;
 
   margin-top: 5px;
   margin-bottom: 5px;
 }
 
 .component-icon-dark {
   background: var(--ion-color-step-850, #27323e);
   color: var(--ion-item-background, #fff);
 }
 