ion-col {
    border: 1px solid grey;
    text-align: center;
  }

  .date-label {
    font-size: 12px;
  }

  ion-alert.custom-alert {
    --backdrop-opacity: 0.7;
  }
  
  .custom-alert .alert-button-group {
    padding: 8px;
  }
  
  button.alert-button.alert-button-confirm {
    background-color: var(--ion-color-success);
    color: var(--ion-color-success-contrast);
  }
  
  .md button.alert-button.alert-button-confirm {
    border-radius: 4px;
  }
  
  .ios .custom-alert button.alert-button {
    border: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2)
  }
  
  .ios button.alert-button.alert-button-cancel {
    border-right: 0;
    border-bottom-left-radius: 13px;
    border-top-left-radius: 13px;
  }
  
  .ios button.alert-button.alert-button-confirm {
    border-bottom-right-radius: 13px;
    border-top-right-radius: 13px;
  }